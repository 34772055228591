import Agent from './agent'

const agent = new Agent()

export const sendEventService = {
  getEventNames: () => agent.get(`/api/enum/event_type`),
  additionalTreatment: () => agent.get(`/api/enum/additionalTreatment`),
  additive: () => agent.get(`/api/enum/additive`),
  application: () => agent.get(`/api/enum/application`),
  bioDegradable: () => agent.get(`/api/enum/bioDegradable`),
  certificateOfAnalysis: () => agent.get(`/api/enum/certificateOfAnalysis`),
  colour: () => agent.get(`/api/enum/colour`),
  colourBasic: () => agent.get(`/api/enum/colourBasic`),
  colourPrinting: () => agent.get(`/api/enum/colourPrinting`),
  componentRemovable: () => agent.get(`/api/enum/componentRemovable`),
  componentType: () => agent.get(`/api/enum/componentType`),
  foodApproved: () => agent.get(`/api/enum/foodApproved`),
  labApproval: () => agent.get(`/api/enum/labApproval`),
  laserPerforations: () => agent.get(`/api/enum/laserPerforations`),
  mfrRange: () => agent.get(`/api/enum/mfrRange`),
  numberOfSubstrates: () => agent.get(`/api/enum/numberOfSubstrates`),
  objectForm: () => agent.get(`/api/enum/objectForm`),
  packagingUnit: () => agent.get(`/api/enum/packagingUnit`),
  packContent: () => agent.get(`/api/enum/packContent`),
  packageType: () => agent.get(`/api/enum/packageType`),
  property: () => agent.get(`/api/enum/property`),
  printFormat: () => agent.get(`/api/enum/printFormat`),
  printType: () => agent.get(`/api/enum/printType`),
  productCategory: () => agent.get(`/api/enum/productCategory`),
  productClass: () => agent.get(`/api/enum/productClass`),
  productionStep: () => agent.get(`/api/enum/productionStep`),
  productSector: () => agent.get(`/api/enum/productSector`),
  productType: () => agent.get(`/api/enum/productType`),
  recommendedUse: () => agent.get(`/api/enum/recommendedUse`),
  recyclateApproval: () => agent.get(`/api/enum/recyclateApproval`),
  svhcDeclaration: () => agent.get(`/api/enum/svhcDeclaration`),
  treatedSide: () => agent.get(`/api/enum/treatedSide`),
  treatmentType: () => agent.get(`/api/enum/treatmentType`),
  type: () => agent.get(`/api/enum/type`),
  wasteOrigin: () => agent.get(`/api/enum/wasteOrigin`),
  webForm: () => agent.get(`/api/enum/webForm`),
  windingCoreType: () => agent.get(`/api/enum/windingCoreType`),

  post: (data) => agent.post(`/api/capture`, { data }),
  getLink: (id) => agent.get(`/api/lgtin/${id}`),
  getXML: (lgtin) => agent.get(`/api/creation_event/${lgtin}`),
}
