import Agent from './agent'

const agent = new Agent()

export const applicationService = {
  version: () => agent.get(`/version`),
}

export * from './agent'
export * from './auth'
export * from './company'
export * from './transactions'
export * from './roles'
export * from './grouping'
export * from './groupingSentEvent'
export * from './permission'
export * from './user'
export * from './marking-identifier'
export * from './enum'
export * from './events'
export * from './SearchService'
export * from './unitConversion'
export * from './translation'
export * from './sentEventsService'
export * from './history'
export * from './transactions-day'
