import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'

import { IsLoginRequired } from 'utils/helpers'
import { getCredentials } from 'services'
import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

import AppConfig from 'config.json'

/**
 * TODO: we need to refactor this part
 * we should remove the layout part and just focus on pages
 *
 * Login
 * Default
 * ProductDataSearch (Demo case)
 * 404 (not found)
 *
 * and we should put all labels out of this file and use App.props for name, and routes configuration
 * fill up should happen in index.js using <App as component
 *
 * and the login required stuff should be moved out.
 * if App only knows the routes provided by props.routes <App routes={routes} for example
 * no need to check here for login stuff. just need to filter out routes before passing in.
 * App is mainly a connection between User (which is using URLs) and type of Pages which he/she trying
 * to access
 *
 * pages care about components which the use. so default should decide which content (component to load) not
 * AppContent. just first impression
 */

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const NotFound = () => (
  <div>
    <h3>404 page not found</h3>
    <p>We are sorry but the page you are looking for does not exist .</p>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Home = React.lazy(() => import('./components/content/Home'))
const LegalNotice = React.lazy(() => import('./components/content/LegalNotice/legalNotice'))
const PrivacyPolicy = React.lazy(() => import('./components/content/privacyPolicy/privacyPolicy'))

const App = (props) => {
  const userDetails = getCredentials()
  const roles = userDetails?.role || null
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer style={{ width: 'fit-content' }} />
        {userDetails ? (
          roles && roles.includes('ADMIN') ? (
            <Switch>
              <Route
                path={[
                  '/',
                  '/login',
                  '/gtin/:gtin/lot/:lot',
                  '/01/:gtin/10/:lot',
                  '/event-history',
                  '/read-rights/gtin/:gtin/lot/:lot',
                  '/mappings',
                  '/companies',
                  '/user',
                  '/grouping',
                  '/send-event-grouping',
                  '/roles',
                  '/marking-ids',
                  '/event-database',
                  '/legal-notice',
                  '/privacy-policy',
                ]}
                name={AppConfig.brand.name}
                render={(props) => <DefaultLayout {...props} />}
              />
              <Route component={NotFound} />
            </Switch>
          ) : (
            <Switch>
              <Route
                path={[
                  '/mappings',
                  '/companies',
                  '/user',
                  '/grouping',
                  '/send-event-grouping',
                  '/roles',
                  '/marking-ids',
                  '/event-database',
                  '/translations',
                  '/units',
                ]}
                name={AppConfig.brand.name}
                render={(props) => <Redirect to="/" />}
              />
              <Route
                path={[
                  '/',
                  '/login',
                  '/gtin/:gtin/lot/:lot',
                  '/01/:gtin/10/:lot',
                  '/event-history',
                  '/read-rights/gtin/:gtin/lot/:lot',
                ]}
                name={AppConfig.brand.name}
                render={(props) => <DefaultLayout {...props} />}
              />

              <Route component={NotFound} />
            </Switch>
          )
        ) : (
          <Switch>
            <Route
              path="/login"
              name={`${AppConfig.brand.name} Login Page`}
              render={(props) => <Login {...props} />}
            />
            <Route path="/legal-notice" name="Legal Notice" render={(props) => <LegalNotice />} />
            <Route
              path="/privacy-policy"
              name="Privacy Policy"
              render={(props) => <PrivacyPolicy />}
            />
            {!IsLoginRequired() && (
              <Route
                path="/gtin/:gtin/lot/:lot"
                name={AppConfig.brand.name}
                render={(props) => <Home {...props} />}
              />
            )}
            {!IsLoginRequired() && (
              <Route
                path="/01/:gtin/10/:lot"
                name={AppConfig.brand.name}
                render={(props) => <Home {...props} />}
              />
            )}
            ,
            {!IsLoginRequired() && (
              <Route
                path="/ident/:ident"
                name={AppConfig.brand.name}
                render={(props) => <Home {...props} />}
              />
            )}
            <Redirect path="/" to="/login" />
            <Route component={NotFound} />
          </Switch>
        )}
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
