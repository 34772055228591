import Agent from './agent'

const agent = new Agent()

export const accessControlEnum = {
  all: () => agent.get('/api/enum/outputfields'),
}

export const aggrigationRulesEnum = {
  all: () => agent.get('/api/enum/eventTypes'),
}

export const productionStepsEnum = {
  all: () => agent.get('/api/enum/productionStep'),
}
