import Agent from './agent'

const agent = new Agent()

export const searchService = {
  one: (gtin, lot, debug = false) => {
    if (gtin) {
      return agent.get(`/api/access/01/${gtin}/10/${lot}?showErrors=${debug}`)
    } else {
      return Promise.reject(new Error('gtin is missing'))
    }
  },
  getLgtin: (ident) => {
    if (ident) {
      return agent.get(`/api/marking_identifier/${ident}`)
    } else {
      return Promise.reject(new Error('ident is missing'))
    }
  },
  getHistory: (gtin, lot, debug = false) => {
    if (gtin) {
      return agent.get(`/api/history/gtin/${gtin}/lot/${lot}?showErrors=${debug}`)
    } else {
      return Promise.reject(new Error('gtin is missing'))
    }
  },
}
